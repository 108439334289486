<template>
  <div>
    <b-card
      title="Confirm Payment"
      class="payment-confirm-card"
    >
      <p class="font-weight-bold">
        1. Please enter your PIN when prompted on your phone.
      </p>
      <p class="font-weight-bold">
        2. Wait for the confirmation message.
      </p>
      <p class="font-weight-bold">
        3. Click Complete to confirm payment.
      </p>
      <br>
      Confirm Payment for
      <h5>
        Invoice
        <span class="invoice-number">#{{ invoiceId }}</span>
      </h5>
      <b-button
        variant="outline-secondary"
        :to="{ name: 'invoice-select-pay-method', params: {id: invoiceId}}"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        class="float-right"
        @click="checkPayment(invoiceId)"
      >
        {{ !isLoading ? "Complete" : "Processing..." }}
        <b-spinner
          v-if="isLoading"
          small
        />
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BFormInput,
  BModal,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import invoiceStoreModule from './invoiceStoreModule'
import useInvoiceList from './useInvoiceList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormInput,
    BModal,
    BFormGroup,
    BSpinner,
  },
  setup() {
    const invoiceId = router.currentRoute.params.id

    const INVOICE_APP_STORE_MODULE_NAME = 'invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const { isLoading, checkPayment } = useInvoiceList()

    return {
      isLoading,
      invoiceId,
      checkPayment,
    }
  },
}
</script>

<style scoped>
  .payment-confirm-card {
    width: 50%
  }
  @media only screen and (max-width: 992px) {
    .payment-confirm-card {
      width: 100%;
    }
  }
  </style>
